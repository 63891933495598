import _styled from "styled-components";
import { Inter } from 'next/font/google';
import Head from 'next/head';
import { createGlobalStyle } from 'styled-components';
const GlobalStyle = createGlobalStyle(["body{", "}*::selection{", ";}"], {
  "fontFamily": "ui-sans-serif, system-ui, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"",
  "--tw-text-opacity": "1",
  "color": "rgb(17 24 39 / var(--tw-text-opacity, 1))",
  "WebkitFontSmoothing": "antialiased",
  "MozOsxFontSmoothing": "grayscale"
}, {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(79 70 229 / var(--tw-bg-opacity, 1))",
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity, 1))"
});
const inter = Inter({
  subsets: ['latin']
});
const Content = _styled.section.withConfig({
  displayName: "Layout__Content",
  componentId: "sc-1f5165s-0"
})({
  "margin": "auto",
  "maxWidth": "48rem",
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "paddingBottom": "2rem"
});
const Layout = props => <>
        <Head>
            <meta name="description" content="Full stack developer with a passion for product development & technology" />
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="manifest" href="/site.webmanifest" />
            <link rel="me" href="https://github.com/jenssegers" />
            <link rel="webmention" href="https://webmention.io/jenssegers.com/webmention" />
            <link rel="pingback" href="https://webmention.io/jenssegers.com/xmlrpc" />
            <link rel="alternate" href="https://jenssegers.com/api/rss" type="application/rss+xml" title="RSS Feed for jenssegers.com" />
        </Head>
        <GlobalStyle />

        <main className={`${inter.className}`}>
            {props.children}
        </main>
    </>;
Layout.Content = Content;
export default Layout;